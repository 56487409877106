
import emailjs from 'emailjs-com'
import React, { useState, useRef } from 'react';

const initialState = {
  from_name: '',
  mobile: '',
  email: '',
  adress: '',
}
export const Services = (props) => {
  const [{ from_name, mobile, email, adress }, setState] = useState(initialState)
  const formRef = useRef();


  const handleChange = (e) => {
    const { from_name, value } = e.target
    setState((prevState) => ({ ...prevState, [from_name]: value }))
  }
  const clearState = () => setState({ ...initialState })


  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(from_name, mobile, email, adress)
    emailjs
      .sendForm(
        'service_5y1zmpm', 'template_9tpgb4t', e.target, 'jKNyC5_s4IJu7AASC'
      )
      .then(
        (result) => {
          alert('Your Call Back Request has been submitted , you will get a get from our team within 5-10 min. Thanks for your patience')
          clearState();
          formRef.current.reset();

        },
        (error) => {
          console.log(error.text)
        }
      )
  }
  return (
    <>
      <div className="container-fluid bvrty" id='contact'>
        <div className="container">
          <div className="address">
            <div className="title-address"></div>

            <div className="col-md-6">
              <div className='section-title'>
                <h2>SUBMIT A CALL BACK REQUEST</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form ref={formRef} name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='from_name'
                        className='form-control'
                        placeholder='Full Name*'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                    <div className='form-group'>
                      <input
                        type='number'
                        id='mobile'
                        name='mobile'
                        className='form-control'
                        placeholder='Mobile Number*'
                        required
                        onChange={handleChange}
                        maxLength={10}
                        minLength={10}

                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>

                    </div>
                    <div className='form-group'>
                      <textarea
                        name='adress'
                        id='adress'
                        className='form-control'
                        rows='3'
                        placeholder='Address'

                        onChange={handleChange}
                      ></textarea>
                      <p className='help-block text-danger'></p>
                    </div>
                    <div id='success'></div>

                  </div>
                </div>

                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg' style={{ marginLeft: '20px' }}>
                  Get a Call Back From Us
                </button>
              </form>

            </div>

            <div className="col-md-6">
              <img className="image-responsive touch" src="img/Door-Step-Service.png" alt='contact-image'/>
            </div>
          </div>
        </div >
      </div >
    </>

  )
}
