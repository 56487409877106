export const About = (props) => {
  return (

    <div className="container">
      <div id="about">
        <h1 className="aboutheader-1">RO Customer Care Number &amp; Service Centre <br />
          {/*<span className="aboutheader-2"> Delhi NCR and  All India </span> */}
        </h1>
      </div>

      <div align="center" className="border">
        <img className="img-responsive" src="img/border.jpg" alt='ro service aboutus' />
      </div>
      &nbsp;
      <p style={{ align: "justify" }}>For any RO Water Purifier related issues, our customer care team is ready to help. Just dial  <b><a href="tel:9031062013">9031062013</a></b>  from your registered mobile number, and you’ll receive a call back along with a complaint number. Our technicians will visit your location on the same day to provide expert repair and service, ensuring your system is back in working order in no time.</p>

      <p style={{ align: "justify" }}>As one of the most trusted brands, RO Water Purifier Services has built a solid reputation for delivering high-quality water purifiers. Our systems, used in both homes and offices, are known for their efficiency and durability. We have service centers throughout India, offering repairs, AMC, and installation services to keep your RO running smoothly.<br /><br />

        Our technicians are highly trained and can assist with membrane cleaning and other maintenance tasks, either at your doorstep or in our regional centers. We provide comprehensive service and support for all types of water purification systems, helping you save time and money while ensuring optimal performance.<br /><br />

        RO Water Purifier Services is a leading service provider in India, offering solutions for domestic, commercial, and industrial water treatment equipment. Rest assured, we have the expertise to handle all your RO service needs.<br /><br />

        &nbsp;
      </p>
      <p align="center">
        <a className="btn btn-primary btn-lg" href="tel:9031062013" target="_blank" rel="noreferrer" style={{ fontSize: '24px' }}>Call Now: 9031062013</a>
      </p> 
      <p>For all your RO system needs—from installation to routine service—the RO service center has you covered. If you're facing any issues with your water purifier, our team of skilled technicians is available around the clock. You can reach our 24/7 RO service helpline for support, with coverage extending to almost every state across India. Whether it’s for repairs, maintenance, or servicing, we're here to provide nationwide service anytime you need.<br />
        &nbsp;
      </p>
      <p></p>
      <p></p>
      <p></p>

      <p><strong>RO Service Center Number Locations RO Contact Number</strong></p>

      <table class="table fntt" style={{ border: "1px solid #ccc" }}>
        <thead>
          <tr>
            <th>RO Customer Care Number All India</th>
            <th>RO Contact Number All India</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>RO Service Center Number All India</td>
            <td><a href="tel:9031062013"><strong>9031062013</strong></a></td>
          </tr>
          <tr>
            <td>RO Service Center Number All India</td>
            <td><a href="tel:9031062013"><strong>9031062013</strong></a></td>
          </tr>

        </tbody>
      </table>

    </div>

  );
};
