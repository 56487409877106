import { useState, useEffect } from "react";
import JsonData from "./data/data.json";


import { Navigation } from './components/navigation';
import { Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Copyright } from "./components/copyright";
import Chooseus from "./components/chooseus";
import Works from "./components/works";
import { Footer } from "./components/footer";
import Home from "./Home";
import { PrivacyPolicies } from './components/pages/privacypolicy'

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div className="App">

      <div className="social">
        
          <div className="pop">
          <a className="phonescreen" href="tel:9031062013">
            <img style={{ border: "0" }} src="img/banners/quickenquiry3.png" alt="callus@9031062013" />
          </a>
        </div>
          
      </div>

      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicies />} />

      </Routes>
      <Footer />
      <Copyright />
    </div>
  );
}

export default App;
